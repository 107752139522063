
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  required
} from 'vuelidate/lib/validators'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import PortService from '../services/port-service'
  @Component({
    name: 'AddPortModal'
  })
export default class AddPortModal extends Vue {
    private port: any = {
      id: null,
      name: null,
      created_user: null,
      modified_user: null
    }

    $v: Vuelidate

    @Validations()
    validations = {
      port: {
        name: {
          required
        }
      }
    }

    public showModal (id = 0) {
      if (id !== 0) {
        PortService.getPort(id).then((response) => {
          this.port = response.data;
          (this.$refs.PortModal as any).show()
        })
      }
      (this.$refs.PortModal as any).show()
    }

    public hideModal () {
      this.$v.$reset()
      this.clear();
      (this.$refs.PortModal as any).hide()
    }

    /* private isNumber ($event: any) {
      const charCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $event.preventDefault()
      } else {
        return true
      }
    } */

    public savePort () {
      this.port.created_user = AuthModule.user.fullname
      this.port.modified_user = AuthModule.user.fullname
      this.$v.port.$touch()
      if (this.$v.port.$invalid) return false
      PortService.save(this.port).then((response) => {
        ToastModule.message(response.data.message);
        (this.$parent as any).populatePorts()
        this.reset(this.port)
        this.$v.port.$reset()
      }).catch(error => {
        console.log(error)
      })
    }

    private clear () {
      this.reset(this.port)
    }

    private close () {
      this.$v.$reset()
      this.clear()
      this.hideModal()
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

