import APIService from '@/services/api-service'
const resource = '/port'

class PortService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public save (data: any) {
    return this.connector.post(`${resource}/save`, data)
  }

  public getPort (id: any) {
    return this.connector.get(`port/${id}`)
  }

  public getPorts () {
    return this.connector.get(`${resource}/details`)
  }

  public getPortNames () {
    return this.connector.get(`${resource}/names`)
  }

  /* public updatePort (data: any) {
    return this.connector.put(`${resource}/update`, data)
  } */

  public deletePort (payload: any) {
    return this.connector.delete(`${resource}/delete`, { data: payload })
  }

  // terminal routes
  public saveTerminal (data: any) {
    return this.connector.post(`${resource}/terminal/save`, data)
  }

  public checkTerminal (code: any) {
    return this.connector.get(`${resource}/terminal/check/${code}`)
  }

  public getTerminals () {
    return this.connector.get(`${resource}/terminal/details`)
  }

  public getTerminal (id: any) {
    return this.connector.get(`${resource}/terminal/${id}`)
  }

  /* public updateTerminal (data: any) {
    return this.connector.put(`${resource}/terminal/update`, data)
  } */

  public deleteTerminal (payload: any) {
    return this.connector.delete(`${resource}/terminal/delete`, { data: payload })
  }

  // service routes
  public getServices () {
    return this.connector.get('service/all')
  }

  public deleteService (payload: any) {
    return this.connector.delete('service/delete', { data: payload })
  }
}

export default new PortService()
