
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  required,
  alpha,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import PortService from '../services/port-service'
  @Component({
    name: 'AddTerminalModal'
  })
export default class AddTerminalModal extends Vue {
    private ports: any = []
    private terminal: any = {
      id: null,
      name: null,
      created_user: null,
      modified_user: null,
      port: {
        id: null,
        name: null
      },
      code: null
    }

    $v: Vuelidate

    @Validations()
    validations = {
      terminal: {
        port: {
          required
        },
        name: {
          required
          // alpha
        },
        code: {
          required,
          alpha,
          minLength: minLength(6),
          maxLength: maxLength(8)
        }
      }
    }

    created () {
      this.boot()
    }

    private boot () {
      this.populatePortNames()
    }

    public showModal (id = 0) {
      if (id !== 0) {
        PortService.getTerminal(id).then((response) => {
          this.terminal = {
            id: response.data.id,
            name: response.data.name,
            port: {
              id: response.data.port_id,
              name: response.data.port_name
            },
            code: response.data.code
          };
          (this.$refs.TerminalModal as any).show()
        })
      }
      (this.$refs.TerminalModal as any).show()
    }

    public hideModal () {
      this.$v.$reset()
      this.clear();
      (this.$refs.TerminalModal as any).hide()
    }

    protected generateTerminalCode () {
      this.terminal.code = this.terminal.name.substring(0, 8).replace(/\s/g, '').toUpperCase()
      if (this.terminal.code.length > 6) this.checkTerminalCode(this.terminal.code)
    }

    protected checkTerminalCode (code: any) {
      PortService.checkTerminal(code).then(response => {
        if (response.data.status) alert('Terminal Already Exist')
      })
    }

    private isNumber ($event: any) {
      const charCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $event.preventDefault()
      } else {
        return true
      }
    }

    private async populatePortNames () {
      const response = await PortService.getPortNames()
      response.data.forEach((element: { id: any; name: any }) => {
        this.ports.push({
          value: {
            id: element.id,
            name: element.name
          },
          text: element.name
        })
      })
    }

    public saveTerminal () {
      this.$v.terminal.$touch()
      if (this.$v.terminal.$invalid) return false
      this.terminal.port_id = this.terminal.port.id
      this.terminal.port_name = this.terminal.port.name
      this.terminal.created_user = AuthModule.user.fullname
      this.terminal.modified_user = AuthModule.user.fullname
      PortService.saveTerminal(this.terminal).then((response) => {
        ToastModule.message(response.data.message);
        (this.$parent as any).populateTerminals()
        this.reset(this.terminal)
        this.$v.terminal.$reset()
      }).catch(error => {
        console.log(error)
      })
    }

    private clear () {
      this.reset(this.terminal)
    }

    private close () {
      this.$v.$reset()
      this.clear()
      this.hideModal()
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

