
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  required,
  helpers
} from 'vuelidate/lib/validators'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import UomJobs from '@/data/uom-jobs.json'
import Currencies from '@/data/currency.json'
import ServiceModalService from '../services/service-modal-service'
import {
  MasterModule
} from '@/store/modules/MasterModule'
const specialalpha = helpers.regex('specialalpha', /^[a-zA-Z0-9\s.-]+$/)
  @Component({
    name: 'ServiceModal'
  })
export default class ServiceModal extends Vue {
    protected uoms = UomJobs
    protected currencies = Currencies
    protected categories: any = []
    protected category: any = {}

    private service: any = {
      id: null,
      name: null,
      unit_of_measurement: null,
      code: null,
      description: null,
      unit_price: null,
      currency: null,
      category_id: null
    }

    $v: Vuelidate

    @Validations()
    validations = {
      service: {
        name: {
          required
        },
        unit_of_measurement: {
          required
        },
        description: {
          specialalpha
        }

      }
    }

    get permissions () {
      return AuthModule.permissions
    }

    public show (id = 0) {
      this.populateCategories()
      if (id !== 0) {
        ServiceModalService.getService(id).then((response) => {
          this.service = response.data
          this.setCategory(response.data.category_id);
          (this.$refs.ServiceModal as any).show()
        })
      }
      (this.$refs.ServiceModal as any).show()
    }

    public hide () {
      this.close();
      (this.$refs.ServiceModal as any).hide()
    }

    protected setCategory (categoryId) {
      if (categoryId !== 0) {
        const category = this.categories.find(category => category.value.id === categoryId)
        this.category = {
          id: category.value.id,
          name: category.value.name
        }
      }
    }

    protected populateCategories () {
      ServiceModalService.getCategories().then((response) => {
        response.data.forEach((element) => {
          this.categories.push({
            value: {
              id: element.id,
              name: element.name
            },
            text: element.name
          })
        })
      })
    }

    protected save () {
      this.service.category_id = this.category.id !== null ? this.category.id : 0
      this.service.created_user = AuthModule.user.fullname
      this.service.modified_user = AuthModule.user.fullname
      this.$v.service.$touch()
      if (this.$v.service.$invalid) return false
      ServiceModalService.save(this.service).then((response) => {
        ToastModule.message(response.data.message)
        MasterModule.setOperationChange();
        (this.$parent as any).populateJobs()
        this.hide()
      }).catch(error => {
        console.log(error)
      })
    }

    protected close () {
      this.reset(this.service)
      this.reset(this.category)
      this.categories = []
      this.$nextTick(() => {
        this.$v.service.$reset()
      })
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

