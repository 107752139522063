
import {
  Component,
  Vue
} from 'vue-property-decorator'
import PortService from './services/port-service'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
import AddPortModal from './components/AddPortModal.vue'
import AddTerminalModal from './components/AddTerminalModal.vue'
import {
  ToastModule
} from '@/store/modules/ToastModule'
  @Component({
    name: 'Vehicle',
    components: {
      AddPortModal,
      AddTerminalModal
    }
  })
export default class Vehicle extends Vue {
    protected perPage = 10
    protected pageOptions: any = [10, 25, 50, 100]
    protected currentPage = 1
    protected searchTable = null
    protected items: any = []
    protected terminals: any = []
    protected fields = [{
      key: 'index',
      label: '#',
      thStyle: {
        width: '3%'
      },
      class: 'text-center'
    },
    {
      key: 'name',
      label: 'Port Name',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'created_user',
      label: 'Created User',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'version',
      class: 'text-center',
      thStyle: {
        width: '5%'
      }
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '8%'
      },
      class: 'text-center'
    }
    ]

    protected terminalFields = [{
      key: 'index',
      label: '#',
      thStyle: {
        width: '3%'
      },
      class: 'text-center'
    },
    {
      key: 'port_name',
      label: 'Port Name',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'code',
      label: 'Code',
      thStyle: {
        width: '5%'
      }
    },
    {
      key: 'name',
      label: 'Terminal Name',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'created_user',
      label: 'Created User',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'version',
      class: 'text-center',
      thStyle: {
        width: '5%'
      }
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '8%'
      },
      class: 'text-center'
    }
    ]

    created () {
      this.boot()
    }

    private boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.populatePorts()
      this.populateTerminals()
    }

    public openPortModal () {
      (this.$refs.addPortModal as any).showModal()
    }

    protected async populatePorts () {
      const response = await PortService.getPorts()
      this.items = response.data
    }

    private editPort (id: any) {
      (this.$refs.addPortModal as any).showModal(id)
    }

    protected deletePort (payload) {
      if (confirm(`Are you sure you want to delete this Port ${payload.name}?`) === true) {
        PortService.deletePort(payload).then(response => {
          this.populatePorts()
          ToastModule.message(response.data.message)
        }).catch(error => {
          console.log(error)
        })
      }
    }

    public openTerminalModal () {
      (this.$refs.addTerminalModal as any).showModal()
    }

    protected async populateTerminals () {
      const response = await PortService.getTerminals()
      this.terminals = response.data
    }

    private editTerminal (id: any) {
      (this.$refs.addTerminalModal as any).showModal(id)
    }

    protected deleteTerminal (payload) {
      if (confirm(`Are you sure you want to delete this Terminal ${payload.name}?`) === true) {
        PortService.deleteTerminal(payload).then(response => {
          this.populateTerminals()
          ToastModule.message(response.data.message)
        }).catch(error => {
          console.log(error)
        })
      }
    }

  /* protected async print () {
      await this.$htmlToPaper('printList')
    } */
}

