import APIService from '@/services/api-service'
const resource = '/service'

class ServiceModalService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public save (data: any) {
    return this.connector.post(`${resource}/save`, data)
  }

  public getService (id: any) {
    return this.connector.get(`${resource}/${id}`)
  }

  public getCategories () {
    return this.connector.get(`${resource}/categories`)
  }
}

export default new ServiceModalService()
