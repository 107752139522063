
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import AddTerminalModal from './components/AddTerminalModal.vue'
import PortService from './services/port-service'

import {
  ToastModule
} from '@/store/modules/ToastModule'
  @Component({
    name: 'Vehicle',
    components: {
      AddTerminalModal
    }
  })
export default class Vehicle extends Vue {
    private isEdit = false
    private searchTable = null
    private ports: any = []
    protected items: any = []

    protected fields = [{
      key: '#',
      label: '#',
      thStyle: {
        width: '3%'
      },
      class: 'text-right'
    },
    {
      key: 'port_name',
      label: 'Port Name',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'code',
      label: 'Code',
      thStyle: {
        width: '5%'
      }
    },
    {
      key: 'name',
      label: 'Terminal Name',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'created_user',
      label: 'Created User',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'version',
      class: 'text-center',
      thStyle: {
        width: '5%'
      }
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '10%'
      },
      class: 'text-center'
    }
    ]

    mounted () {
      this.items = this.items.map(items => ({
        ...items,
        isEdit: false
      }))
    }

    created () {
      this.boot()
    }

    private boot () {
      this.populateTerminals()
      this.populatePortNames()
    }

    protected populatePortNames () {
      PortService.getPortNames().then((response) => {
        response.data.forEach((port) => {
          this.ports.push({
            text: port.name,
            value: {
              id: port.id,
              name: port.name
            }
          })
        })
      })
    }

    public openAddModal () {
      (this.$refs.addTerminalModal as any).showModal()
    }

    protected async populateTerminals () {
      const response = await PortService.getTerminals()
      this.items = response.data.map(item => ({
        ...item,
        isEdit: false,
        modified_user: AuthModule.user.fullname
      }))
    }

    protected isNumber ($event: any) {
      const charCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $event.preventDefault()
      } else {
        return true
      }
    }

    protected editRowHandler (data: any) {
      this.items[data.index].isEdit = !this.items[data.index].isEdit
    }

    protected update (item: any) {
      PortService.updateTerminal(item).then((response: any) => {
        item.isEdit = false
        this.populateTerminals()
        ToastModule.message(response.data.message)
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    protected deleteTerminal (payload) {
      if (confirm(`Are you sure you want to delete this Terminal ${payload.name}?`) === true) {
        PortService.deleteTerminal(payload).then(response => {
          this.populateTerminals()
          ToastModule.message(response.data.message)
        }).catch(error => {
          console.log(error)
        })
      }
    }

    protected async print () {
      await this.$htmlToPaper('printList')
    }
}

