
import {
  Component,
  Vue
} from 'vue-property-decorator'
import PortService from './services/port-service'
import ServiceModal from './components/ServiceModal.vue'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
import {
  ToastModule
} from '@/store/modules/ToastModule'
  @Component({
    name: 'Vehicle',
    components: {
      ServiceModal
    }
  })
export default class Vehicle extends Vue {
    protected perPage = 10
    protected pageOptions: any = [10, 25, 50, 100]
    protected currentPage = 1
    protected searchTable = null
    protected items: any = []
    protected fields = [{
      key: 'index',
      label: '#',
      thStyle: {
        width: '2%'
      },
      class: 'text-right'
    },
    {
      key: 'type',
      label: 'Service',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'code',
      label: 'Code',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'description',
      label: 'Description',
      thStyle: {
        width: '20%'
      }
    },
    {
      key: 'unit_of_measurement',
      label: 'UoM',
      thStyle: {
        width: '5%'
      }
    },
    {
      key: 'created_user',
      label: 'Created User',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '8%'
      },
      class: 'text-center'
    }
    ]

    created () {
      this.boot()
    }

    private boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.populateServices()
    }

    public openAddModal () {
      (this.$refs.ServiceModal as any).show()
    }

    public async populateServices () {
      const response = await PortService.getServices()
      this.items = response.data
    }

    protected edit (id: any) {
      (this.$refs.ServiceModal as any).show(id)
    }

    /* protected isNumber ($event: any) {
      const charCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $event.preventDefault()
      } else {
        return true
      }
    } */

    protected deleteService (payload) {
      if (confirm(`Are you sure you want to delete this Service ${payload.name}?`) === true) {
        PortService.deleteService(payload).then(response => {
          this.populateServices()
          ToastModule.message(response.data.message)
        }).catch(error => {
          console.log(error)
        })
      }
    }

  /* protected async print () {
      await this.$htmlToPaper('printList')
    } */
}

